import React, { useEffect } from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="">
        <Navbar />
        <img className="h-[75vh] sm:h-[90vh] w-full" rel="preload" src="images/contact.webp" alt="" />
      </div>
      <section
        className="min-h-screen px-6 py-6 lg:px-12 lg:py-20 "
        style={{ backgroundColor: "#181818" }}
      >

      <div className="flex flex-col items-center justify-center md:justify-between md:flex-row md:items-start">

        <div className="mb-4 md:mb-0 md:mr-4">
          <h1 className="font-bold mb-4 text-lg text-center md:text-left lg:text-[2rem]">
            CONTACT US
          </h1>
          <p className="text-gray-300 mb-8 text-lg text-center md:text-left">
            Have a question or need assistance? We're here to help!
          </p>
          <form action="" className="flex flex-col">
            <input
              type="text"
              className="mb-8 p-4 bg-transparent border-[2px] border-sec_col rounded-md focus:outline-none"
              placeholder="Enter your name"
              // style={{
              //     backgroundColor: "#181818"
              // }}
            />
            <input
              type="email"
              className="mb-8 p-4 bg-transparent border-[2px] border-sec_col rounded-md focus:outline-none"
              placeholder="Enter your email"
              // style={{
              //     backgroundColor: "#181818"
              // }}
            />
            <textarea
              type="message"
              className="mb-4 p-4 bg-transparent border-[2px] border-sec_col rounded-md focus:outline-none"
              placeholder="Message"
              cols="30"
              rows="6"
            //   style={{
            //       backgroundColor: "#181818"
            //   }}
            />

            <div className="mx-auto md:mx-0">
              <button className="bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition-all duration-300">
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>

        <div className="mb-4 md:mb-0 md:mr-4">
          <h1 className="font-bold text-lg text-sec_col mb-2 text-center md:text-left lg:text-2xl lg:mb-4">OFFICE</h1>
          <p className="mb-4 text-md text-center text-gray-300 md:text-left">BIC, University of Gujrat, Gujrat</p>

          <h1 className="font-bold text-lg text-sec_col mb-2 text-center md:text-left lg:text-2xl lg:mb-4">WORK HOURS</h1>
          <p className="text-md text-center text-gray-300 md:text-left">Monday - Friday (9AM to 5AM)</p>
        </div>

        <div className="">
          <h1 className="font-bold text-sec_col mb-2 text-center text-lg md:text-left lg:text-2xl lg:mb-4">CONNECT</h1>
          <div className="flex items-center justify-center space-x-4 flex-wrap md:block md:space-x-0">

          <div className="flex items-center mb-2 md:text-left lg:mb-4">
            <IoIosMail
              className="text-sec_col"
              style={{ fontSize: "1.5rem" }}
            />
            <Link
              to="mailto:netsflowofficial@gmail.com"
              className="text-md ml-2 text-gray-300 cursor-pointer hover:text-nav_hover hover:drop-shadow-icon_custom transition-all duration-300"
            >
              netsflowofficial@gmail.com
            </Link>
          </div>

          <div className="flex items-center md:text-left mb-4">
          <FaPhoneVolume className="text-sec_col" style={{fontSize: "1.3rem"}}/>
          <Link to="tel:+11234567890" className="text-sm ml-2 text-gray-300 cursor-pointer hover:text-nav_hover hover:drop-shadow-icon_custom transition-all duration-300">
            +92 300 6232929
          </Link>
          </div>

          <div className="flex items-center md:text-left">
          <FaPhoneVolume className="text-sec_col" style={{fontSize: "1.3rem"}}/>
          <Link to="tel:+11234567890" className="text-sm ml-2 text-gray-300 cursor-pointer hover:text-nav_hover hover:drop-shadow-icon_custom transition-all duration-300">
            +92 326 1128525
          </Link>
          </div>

          </div>

        </div>

        </div>

      </section>
      <Footer />
    </>
  );
};

export default Contact;
