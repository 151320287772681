import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";

const NewsLetter = () => {
  return (
    <section className="px-10 py-10 flex items-center lg:px-12 lg:py-20 animation w-full" style={{backgroundColor: "#181818"}}>

    <div className="flex flex-col lg:flex-row lg:flex-1 lg:">
    <div className="">
    <h1
        className="mb-2"
        style={{ fontSize: "2rem" }}
      >
        Join us in Digital Revolution!
      </h1>
      <p className="text-gray-300 mb-2 lg:mb-0">
        Explore our store to discover where this incredible journey of faith in
        literature leads.
      </p>
    </div>
    
    <div className="lg:flex-1 lg:flex lg:justify-end">
    <Link to='/contact'>
    <button className="flex items-center bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition-all duration-300 ">
      CONTACT
      <FaArrowRight className="ml-2"/>
    </button>
    </Link>
    </div>
    </div>
      
    </section>
  );
};

export default NewsLetter;
