import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UserModal from "./UserModal";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleButton, setToggleButton] = useState(true);

  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [img, setImg] = useState(JSON.parse(localStorage.getItem("img")));

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal state
  };

  const handleOpen = () => {
    setToggleButton(!toggleButton);
  };

  const handleClose = () => {
    setToggleButton(!toggleButton);
  };

  const location = useLocation();

  return (
    <nav
      className="flex justify-between items-center px-6 py-4 fixed top-0 w-full lg:px-12"
      style={{
        backgroundColor: colorChange ? "#101010" : "#101010",
        borderBottom: colorChange ? "1px solid #cbcbcb" : "none",
        zIndex: "999",
        opacity: "0.95",
      }}
    >
      <div className="">
        <Link to="/">
          <img
            className="h-[3.5rem] self-center drop-shadow-logo_custom"
            rel="preload"
            src="./images/netsflow.webp"
            alt="NetsFlow"
          />
        </Link>
      </div>

      <div>
        <ul className={`${toggleButton ? "desktop" : "desktop active"} text-sm`}>
          <Link to="/">
            <li className={location.pathname === '/' ? 'active' : 'mx-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300'}>
              HOME
            </li>
          </Link>

          <Link to="/services">
            <li className={location.pathname === '/services' ? 'active' : 'mx-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300'}>
              SERVICES
            </li>
          </Link>

          <Link to="/ourwork">
            <li className={location.pathname === '/ourwork' ? 'active' : 'mx-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300'}>
              OUR WORKS
            </li>
          </Link>

          <Link to="/team">
            <li className={location.pathname === '/team' ? 'active' : 'mx-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300'}>
              TEAM
            </li>
          </Link>

          <Link to="/about">
            <li className={location.pathname === '/about' ? 'active' : 'mx-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300'}>
              ABOUT US
            </li>
          </Link>

          <Link to="/contact">
            <li className={location.pathname === '/contact' ? 'active' : 'mx-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300'}>
              CONTACT
            </li>
          </Link>

          {token ? (
            <>
              <img
                className="rounded-full mx-5 cursor-pointer h-10  hover:translate-y-[-4px]"
                rel="preload"
                src={img}
                alt="user"
                onClick={toggleModal}
              />
            </>
          ) : (
            <>
              <Link to="/login">
                <li className="ml-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300">
                  LOGIN
                </li>
              </Link>
            </>
          )}
        </ul>
      </div>

      <div id="mobile">
        {toggleButton ? (
          <span
            className="ml-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300"
            onClick={handleOpen}
          >
            <GiHamburgerMenu className="text-4xl"/>
          </span>
        ) : (
          <span
            className="ml-5 cursor-pointer text-gray-300 hover:text-nav_hover hover:drop-shadow-icon_custom hover:translate-y-[-4px] transition duration-300"
            onClick={handleClose}
          >
            <IoCloseSharp className="text-4xl"/>
          </span>
        )}
      </div>

      {isModalOpen && <UserModal onClose={toggleModal} />}
    </nav>
  );
};

export default Navbar;
