import React, { useState } from "react";
import { GiZigzagHieroglyph } from "react-icons/gi";
import { Data } from "../data";
import { Link } from "react-router-dom";

const Cards = () => {
  const [data, setData] = useState(Data);
  return (
    <section
      className="min-h-screen px-6 py-10 animation4 lg:px-4 w-full"
    >
      <h1 className="font-bold text-center" style={{ fontSize: "2rem" }}>
        NETSFLOW DISTINCTIVES
      </h1>
      <div className="flex justify-center items-center drop-shadow-icon_custom">
        <GiZigzagHieroglyph
          className="text-sec_col"
          style={{ fontSize: "4rem" }}
        />
      </div>
      <div className="flex justify-center items-center flex-wrap mt-4">
        {data.map((elem) => (
          
          <div
            className={`animation flex items-center justify-center flex-col p-6 hover:shadow-btn_smaller_custom transition-all duration-300 rounded-3xl cursor-pointer sm:w-[40%] sm:mb-4 sm:mr-8 lg:py-6 lg:px-12 lg:w-[30%] lg:mr-8 lg:mb-8 lg:hover:shadow-btn_custom lg:hover:translate-y-[-12px] ${
              elem.id === 3 || elem.id === 5 ? "remove-mr-third" : ""
            }`}
            key={elem.id}
            // style={{border: "2px solid red"}}
          >
          <Link to={elem.link} className="flex items-center justify-center flex-col">
            <elem.icon
              className="text-gray-400 mb-4 hover:text-sec_col transition-all duration-300 text-[6.5rem]"
            />
            <h1 className="mb-2 font-bold" style={{ fontSize: "1.5rem" }}>
              {elem.title}
            </h1>
            <p className="text-center text-gray-300">
              {elem.description}
            </p>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Cards;
