import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { ServicesData } from "../data";
import { FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Services = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <Navbar />
        <section className="h-[75vh] sm:h-[90vh] relative flex flex-col items-center justify-center">
          <div
            className="h-[75vh] sm:h-[90vh] absolute top-0 w-full left-0 z-20"
            style={{
              backgroundColor: "#181818",
              opacity: "0.8",
            }}
          ></div>

          <div
            className="h-[75vh] sm:h-[90vh] absolute top-0 w-full left-0 bg-cover bg-center z-10"
            style={{
              backgroundImage: "url('images/service.webp')",
              // zIndex: "-100",
            }}
          ></div>

          <div className="h-[75vh] sm:h-[90vh] absolute top-0 left-0 z-30 w-full flex flex-col items-center justify-center text-center px-6 lg:px-0">
            <h1 className="mb-2 text-[2rem] sm:mb-4 sm:text-[3rem]">
              SERVICES
            </h1>
            <p className="text-gray-300 text-md lg:w-[60%] lg:text-xl">
              Step into the spotlight of online success! Explore our range of
              top-tier services tailored to exceed your expectations. From
              strategic guidance to technical mastery, unlock excellence with
              us.
            </p>
          </div>
        </section>
      </div>

      <section className="flex flex-col py-4 items-center h-full px-6 mb-2 lg:px-12 lg:py-0 lg:h-[60vh] lg:justify-between lg:flex-row" >
        <div className="text-lg font-bold mb-4 lg:flex-1 lg:text-[2.5rem] lg:mb-0">
          WHAT WE DO
        </div>

        <div className="text-md text-center lg:flex-1 lg:text-lg lg:text-left">
          We specialize in crafting digital experiences that leave a lasting
          impression. Whether it's designing stunning websites, creating
          engaging mobile apps, or optimizing your online presence, we're here
          to help you succeed.
        </div>
      </section>

      <section className="min-h-screen px-4 py-4 lg:mb-10 lg:py-0">
        <div className="flex items-center justify-center flex-wrap lg:justify-start">
          {ServicesData.map((elem) => {
            return (
                <div className="mb-4 flex items-center justify-center flex-col sm:w-[50%] sm:p-2 lg:w-[28%] lg:mb-0 lg:m-8 lg:block animation" key={elem.id}>
                  <elem.icon className="mb-4 text-sec_col text-[4.5rem] hover:text-[4.8rem] transition-all duration-300 h-[4.8rem] lg:mb-6" />
                  <h1 className="mb-2 text-lg lg:mb-4">{elem.title}</h1>
                  <span className="text-sec_col font-bold text-lg">
                    {elem.descriptionUnique}
                  </span>
                  <span className="text-gray-300 text-center lg:text-left">{elem.description}</span>
                </div>
            );
          })}
        </div>
      </section>

      <section className="h-screen relative animation">

        <div
          className="h-screen absolute top-0 left-0 z-10 w-full bg-cover bg-center"
          style={{
            backgroundImage: "url('images/service_approach.webp')",
          }}
        ></div>

        <div
          className="h-screen absolute top-0 left-0 z-20 w-full"
          style={{
            backgroundColor: "#181818",
            opacity: "0.89",
          }}
        ></div>
        
        <div className="h-full absolute top-0 left-0 z-30 w-full flex items-center px-4 py-4 lg:px-12 lg:py-0 lg:justify-between">
          <div className="flex items-center md:block md:flex-1 approachHidden">
            <img draggable="false" rel="preload" className="h-[70vh]" src="images/service_illustration.webp" alt="" />
          </div>
          <div className="flex items-center justify-center flex-col md:flex-1 md:block">
            <h1 className="text-lg font-bold mb-2 text-center lg:text-[2rem] lg:mb-4 md:text-left">APPROACH</h1>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-4 lg:text-lg">
              We utilize effective strategies and innovative techniques to
              deliver exceptional results for our clients. Our approaches are
              designed to meet your specific requirements and achieve measurable
              success.
            </p>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-8 lg:text-lg">
              We keep a close watch on your project to ensure smooth progress
              and tackle any issues proactively. With our vigilant monitoring,
              nothing falls through the cracks, keeping your project moving
              forward smoothly.
            </p>
            <div className="">
            <Link to='/contact'>
              <button className="flex items-center bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition duration-300 ">
                GET STARTED
                <FaArrowRight className="ml-2" />
              </button>
              </Link>
            </div>
          </div>
        </div>

      </section>

      <Footer/>
    </>
  );
};

export default Services;
