import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { teamData } from "../data";
import { FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Team = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <Navbar />
        <section className="h-[75vh] sm:h-[90vh] relative flex flex-col items-center justify-center">
          <div
            className="h-[75vh] sm:h-[90vh] absolute top-0 w-full left-0 z-20"
            style={{
              backgroundColor: "#181818",
              opacity: "0.8",
            }}
          ></div>

          <div
            className="h-[75vh] sm:h-[90vh] absolute top-0 w-full left-0 bg-cover bg-center z-10"
            style={{
              backgroundImage: "url('images/team.webp')",
              // zIndex: "-100",
            }}
          ></div>

          <div className="h-[75vh] sm:h-[90vh] absolute top-0 left-0 z-30 w-full flex flex-col items-center justify-center text-center px-6 lg:px-0">
            <h1 className="mb-2 text-[2rem] sm:mb-4 sm:text-[3rem]">
              TEAM
            </h1>
            <p className="text-gray-300 text-md lg:w-[60%] lg:text-xl">
            Meet the dedicated minds behind NetsFlow! Our expert team combines creativity with technical skill to bring your digital vision to life. Together, we strive for innovation, collaboration, and success in every project we undertake.
            </p>
          </div>
        </section>
      </div>

      <section className="flex flex-col py-4 items-center h-full px-6 mb-2 lg:px-12 lg:py-0 lg:h-[60vh] lg:justify-between lg:flex-row" >
        <div className="text-lg font-bold mb-4 lg:flex-1 lg:text-[2.5rem] lg:mb-0">
        MEET OUR TEAM
        </div>

        <div className="text-md text-center lg:flex-1 lg:text-lg lg:text-left">
        Our team is the backbone of our success. A diverse group of experts with a shared passion for innovation, we collaborate to create digital solutions that meet and exceed your expectations. Each member brings unique skills and creativity to every project, ensuring we deliver excellence at every step.
        </div>
      </section>

      <section className="min-h-screen px-4 py-4 lg:mb-10 lg:py-0">
        <div className="flex items-center justify-center flex-wrap lg:justify-start">
          {teamData.map((elem) => {
            return (
                <div className="mb-4 flex items-center justify-center flex-col sm:w-[50%] sm:p-2 lg:w-[28%] lg:mb-0 lg:m-8 lg:block animation" key={elem.id}>
                <img rel="preload" src={elem.img} alt="Member Photo" className="h-[210px]"/>
                <h1 className="text-lg mt-2 text-gray-300">{elem.name}</h1>
                <h1 className="text-md mt-2 mb-2 text-gray-300">{elem.role}</h1>
                <span className="text-sec_col font-bold text-lg">
                    {elem.introUnique}
                  </span>
                <span className="text-gray-300 text-center lg:text-left">{elem.intro}</span>
                </div>
            );
          })}
        </div>
      </section>

      <section className="h-screen relative animation">

        <div
          className="h-screen absolute top-0 left-0 z-10 w-full bg-cover bg-center"
          style={{
            backgroundImage: "url('images/service_approach.webp')",
          }}
        ></div>

        <div
          className="h-screen absolute top-0 left-0 z-20 w-full"
          style={{
            backgroundColor: "#181818",
            opacity: "0.89",
          }}
        ></div>
        
        <div className="h-full absolute top-0 left-0 z-30 w-full flex items-center px-4 py-4 lg:px-12 lg:py-0 lg:justify-between">
          <div className="flex items-center md:block md:flex-1 approachHidden">
            <img draggable="false" rel="preload" className="h-[70vh]" src="images/our_collaboration.webp" alt="" />
          </div>
          <div className="flex items-center justify-center flex-col md:flex-1 md:block">
            <h1 className="text-lg font-bold mb-2 text-center lg:text-[2rem] lg:mb-4 md:text-left">OUR COLLABORATION</h1>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-4 lg:text-lg">
            Our team's strength is rooted in the way we work together, blending our diverse skills and expertise to deliver exceptional results. Each team member brings something unique to the table, and through collaboration, we turn ideas into actionable strategies.
            </p>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-8 lg:text-lg">
            At NetsFlow, communication is at the core of our collaboration. By maintaining open and transparent dialogue, we foster dynamic problem-solving and adaptability, ensuring we meet challenges head-on.
            </p>
            <div className="">
            <Link to='/contact'>
              <button className="flex items-center bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition duration-300 ">
                GET STARTED
                <FaArrowRight className="ml-2" />
              </button>
              </Link>
            </div>
          </div>
        </div>

      </section>

      <Footer/>
    </>
  );
};

export default Team;
