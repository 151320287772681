import { RiTeamLine } from 'react-icons/ri';
import { MdMiscellaneousServices } from 'react-icons/md';
import { AiOutlineProduct } from 'react-icons/ai';
import { MdContactSupport } from 'react-icons/md';
import { IoMdContacts } from 'react-icons/io';
import { BsPcDisplayHorizontal } from 'react-icons/bs';
import { TbDeviceDesktopCode } from 'react-icons/tb';
import { TbDeviceMobileCode } from 'react-icons/tb';
import { LuWebhook } from 'react-icons/lu';
import { TbWorldSearch } from 'react-icons/tb';
import { FaRegPaperPlane } from 'react-icons/fa';
import { MdOutlineRocketLaunch } from 'react-icons/md';
import { MdVideoSettings } from 'react-icons/md';


export const Data = [
  {
    id: 1,
    title: 'SERVICES',
    description: 'Embrace the change and the change will transform you',
    icon: MdMiscellaneousServices,
    link: '/services'
  },
  {
    id: 2,
    title: 'TEAM',
    description:
      'Our exceptional team will make clear our achievements',
    icon: RiTeamLine,
    link: '/team'
  },
  {
    id: 3,
    title: 'PRODUCTS',
    description: 'Discover our products, and our quality will speak for itself',
    icon: AiOutlineProduct,
    link: '/ourwork'
  },
  {
    id: 4,
    title: 'ABOUT US',
    description:
      'Discover who we are and how our values guide our mission',
    icon: MdContactSupport,
    link: '/about'
  },
  {
    id: 5,
    title: 'CONTACT',
    description:
      'Reach out to us and experience the support that sets you free',
    icon: IoMdContacts,
    link: '/contact'
  }
]


export const ServicesData = [
  {
    id: 1,
    title: 'UI/UX DESIGNING',
    descriptionUnique: 'Websites that wow!',
    description: ' Design a stunning, user-friendly website that informs and converts visitors into customers.',
    icon: BsPcDisplayHorizontal,
  },
  {
    id: 2,
    title: 'WEB DEVELOPMENT',
    descriptionUnique: 'Build robust, scalable websites!',
    description: ' Develop dynamic, high-performance websites that engage users and drive growth.',
    icon: TbDeviceDesktopCode,
  },
  {
    id: 3,
    title: 'MOBILE APPS',
    descriptionUnique: 'Apps that rock!',
    description: ' Create mobile experiences that engage users and boost your on-the-go sales.',
    icon: TbDeviceMobileCode,
  },
  // {
  //   id: 4,
  //   title: 'DIGITAL MARKETING',
  //   descriptionUnique: 'Unleash the power of online!',
  //   description: ' Reach new fans and skyrocket your brand with targeted campaigns.',
  //   icon: LuWebhook,
  // },
  {
    id: 4,
    title: 'WORDPRESS DEVELOPMENT',
    descriptionUnique: 'Get website, in no times! ',
    description: ' Make your websites in no time at minimum cost.',
    icon: TbWorldSearch,
  },
  // {
  //   id: 6,
  //   title: 'BRAND DESIGNING',
  //   descriptionUnique: 'Make your brand unforgettable!',
  //   description: ' Design a visual identity that speaks directly to your ideal customers.',
  //   icon: FaRegPaperPlane,
  // },
  // {
  //   id: 7,
  //   title: 'GOOGLE ADS',
  //   descriptionUnique: 'Skyrocket your reach!',
  //   description: ' Boost your traffic and increase your business visibility and ROI.',
  //   icon: MdOutlineRocketLaunch,
  // },
  // {
  //   id: 8,
  //   title: 'VIDEO CREATION',
  //   descriptionUnique: 'Ditch the boring!',
  //   description: ' Tell your brand story with engaging videos that capture hearts and minds.',
  //   icon: MdVideoSettings,
  // }
]

export const OurWorkData = [
  {
    id: 1,
    title: 'COLLEGE MASTER MIND',
    description: '',
    img: 'images/college-master-mind.webp',
    link: 'https://collegemastermind.com/'
  },
  {
    id: 2,
    title: 'CODER BS',
    description: '',
    img: 'images/coder-bs.webp',
    link: 'https://coderbs.netlify.app/'
  },
  {
    id: 3,
    title: 'FAST STREAMING',
    description: '',
    img: 'images/fast-streaming.webp',
    link: 'https://fast-streaming.netlify.app/'
  },
  // {
  //   id: 4,
  //   title: 'HAJVERY GROUP',
  //   description: '',
  //   img: 'images/service.webp',
  // },
  // {
  //   id: 5,
  //   title: 'HAJVERY GROUP',
  //   description: '',
  //   img: 'images/service.webp',
  // },
]


export const teamData = [
  {
    id: 1,
    name: 'MUHAMMAD UMAIR',
    role: 'FOUNDER',
    introUnique: 'Full-Stack Mobile Developer',
    intro: ' with expertise in Flutter and backend services, I build scalable and robust mobile applications. My focus is on ensuring high quality, optimized performance, and strong security, while successfully turning client ideas into fully realized products.',
    img: '/images/MuhammadUmair.webp',
  },
  {
    id: 2,
    name: 'MUHAMMAD FAIZAN',
    role: 'CO-FOUNDER',
    introUnique: 'Full-Stack Web Developer',
    intro: ' Leveraging the MERN stack and backend skills, I design and build high-performing web applications. My dedication is to ensuring robust functionality, high security, and excellent user experience while turning your vision into effective solution.',
    img: '/images/MuhammadFaizan.webp',
  },
  {
    id: 3,
    name: 'ZAIN IQBAL',
    role: 'CTO',
    introUnique: 'Experienced Flutter Developer',
    intro: ' specializing in building cross-platform mobile apps, I bring innovative and sleek designs to life with Flutter. From concept to deployment, I ensure smooth performance and engaging user experiences.',
    img: '/images/ZainIqbal.webp',
  },
  {
    id: 4,
    name: 'MUBASHAR MEHMOOD',
    role: 'CMO',
    introUnique: 'Business Growth Strategist',
    intro: ' with expertise in critical thinking, strategic planning, and brand mobilization, I drive business growth and close key deals. My focus is on crafting winning proposals, mastering negotiation, and delivering impactful content.',
    img: '/images/MubasharMehmood.webp',
  }
]