import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section className="flex px-6 items-center min-h-[75vh] w-full justify-between lg:px-12 lg:min-h-[117vh]">
    <div className="flex flex-col text-start">
    <h1
        className="font-bold text-xl sm:text-2xl lg:text-[3rem] lg:mb-8"
        style={{ color: "#9c89b3"}}
      >
        WE’RE YOUR PARTNER
      </h1>
      <h1 className="font-bold text-xl sm:text-2xl lg:text-[3rem] lg:mb-8">
      TEAM WITH A
      </h1>
      <h1 className="mb-4 font-bold text-xl sm:text-2xl lg:text-[3rem]">
        GLOBAL VISION
      </h1>
      <p className="text-gray-300 mb-4 sm:hidden">Transforming ideas into digital excellence with innovative web and Flutter development solutions.</p>

      <div className="">
      <Link to='/contact'>
        <button
          className="bg-sec_col py-4 px-4 text-sm border-2 border-sec_col text-white font-bold cursor-pointer mx-auto my-4 drop-shadow-btn_custom hover:bg-transparent hover:border-white hover:text-white transition-all duration-300"
          aria-label="Contact Form"
        >
          CONTACT US
        </button>
        </Link>
      </div>
    </div>

    <div className="drop-shadow-hero_img_smaller_custom slide-in h-[225px] w-[225px] hidden1 sm:h-[260px] sm:w-[260px] lg:h-[400px] lg:w-[400px] lg:drop-shadow-hero_img_custom">
      <img draggable="false" rel="preload" src="./images/Programmer.webp" alt="" className="bounce-animation"/>
    </div>
      
    </section>
  );
};

export default HeroSection;
