import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="">
        <Navbar />

        <section className="min-h-[75vh] sm:min-h-[90vh] flex flex-col items-center justify-center px-6 lg:justify-between lg:flex-row lg:px-12 ">
          <div className="mb-4 approachHidden lg:flex-1">
            <img
              className="h-[80px] lg:w-[180px] lg:h-[180px]"
              rel="preload"
              src="images/netsflow.webp"
              alt=""
            />
          </div>

          <div className="flex items-center flex-col text-center lg:justify-start lg:w-[65%] lg:items-start lg:text-left">
            <span className="text-gray-300 text-md lg:hidden">
              NetsFlow is a leading provider of innovative web and Flutter
              development solutions, dedicated to crafting captivating digital
              experiences.</span><span className="text-gray-300 text-md aboutHidden">NetsFlow is a leading provider of innovative web and Flutter
              development solutions, dedicated to crafting captivating digital
              experiences. With a passion for innovation, our skilled developers
              create exceptional digital products that drive business growth,
              transforming your vision into reality. We specialize in delivering
              top-quality, tailored solutions to meet your unique needs. Whether
              you're looking for a web or Flutter development partner, we bring
              your ideas to life, combining your vision with our expertise to
              build something amazing together.
            </span>
            <div>
            <Link to='/contact'>
              <button className="flex items-center bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition-all duration-300">
                GET STARTED
                <FaArrowRight className="ml-2" />
              </button>
              </Link>
            </div>
          </div>
        </section>
      </div>

      <section className="min-h-screen w-full flex items-center px-6 py-6 md:justify-between lg:px-12 lg:py-0" style={{ backgroundColor: "#181818" }}>
        <div className="flex-1 relative approachHidden animation md:min-h-screen md:flex md:items-center">

          <div className="md:h-[230px] md:w-[200px] bg-cover bg-center lg:h-[330px] lg:w-[250px]"
          style={{
            backgroundImage: "url('images/mission.webp')",
            // zIndex: "-100",
          }}
          >
          </div>

          <div className="absolute top-[50%] left-[120px] md:h-[180px] md:w-[200px] bg-cover bg-center lg:h-[280px] lg:w-[300px] lg:top-[40%]"
          style={{
            backgroundImage: "url('images/vision.webp')",
            // zIndex: "-100",
          }}
          >
          </div>

        </div>

        <div className="flex flex-col md:flex-1 lg:justify-start">
          <h1 className="font-bold text-2xl mb-2 text-center md:text-left lg:text-[2rem]">
            Mission
          </h1>
          <p className="text-gray-300 mb-4 text-center md:text-left lg:mb-8">
          At NetsFlow, our mission is to transform digital ideas into reality, empowering businesses to excel in the ever-evolving digital landscape. We are committed to delivering cutting-edge web and Flutter development solutions that captivate audiences and drive growth.
          </p>

          <h1 className="font-bold text-2xl mb-2 text-center md:text-left lg:text-[2rem]">
            Vision
          </h1>
          <p className="text-gray-300 text-center md:text-left">
          At NetsFlow, we envision a future where innovative web and Flutter solutions seamlessly empower businesses to thrive in the digital realm. We strive to be at the forefront of digital transformation, helping businesses connect, grow, and make an impact. Our goal is to lead with creativity and expertise, making digital success accessible and achievable for all.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
