import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import Cards from "../components/Cards";
import { GiZigzagHieroglyph } from "react-icons/gi";
import NewsLetter from "../components/NewsLetter";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
      <section
        className=""
        // style={{
        //   backgroundImage: "url('/images/hero_img.jpg')",
        //   zIndex: "-100",
        // }}
      >
        <Navbar />
      </section>

      <section className="min-h-[75vh] w-full lg:min-h-screen flex flex-col items-center justify-center">
        <HeroSection />

      </section>

      <section className="min-h-screen flex justify-center py-10 px-6 flex-col animation lg:px-12 lg:justify-between lg:flex-row xl:min-h-[60vh]" style={{backgroundColor: "#181818"}}>
        <div className="flex-col flex items-center lg:flex-1 lg:pl-8 ">
          <h1 className="font-bold text-center lg:w-[35%] lg:text-left" style={{ fontSize: "2rem" }}>
          NetsFlow Your Digital Solution
          </h1>
          <div className="drop-shadow-icon_custom lg:w-[35%]">
            <GiZigzagHieroglyph
              className="text-sec_col"
              style={{ fontSize: "4rem" }}
            />
          </div>
        </div>

        <div className="flex-1">
          <h1
            className="font-playfair mb-7 text-center lg:w-[60%] lg:text-left"
            style={{ fontSize: "1rem" }}
          >
            "Today an idea, tomorrow a digital success"
          </h1>
          <p className="mb-5 text-gray-300 lg:w-[60%]">
          Welcome to NetsFlow, your premier source for cutting-edge digital solutions. Whether you're seeking innovative web design, seamless Flutter apps, or tailored development services, NetsFlow has it all. Our platform provides expertise in crafting digital experiences that captivate and convert
          </p>
          <p className="mb-5 text-gray-300 lg:w-[60%]">
          With top-notch development skills, personalized service, and a commitment to excellence, NetsFlow ensures your digital vision becomes a reality with ease and efficiency
          </p>
          <Link to='/about'>
          <button className="bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer mx-auto my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition-all duration-300">
            FIND OUT MORE
          </button>
          </Link>
        </div>
      </section>

      <Cards />

      <NewsLetter />

      <Footer />
    </>
  );
};

export default Home;
