import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { OurWorkData, ServicesData } from "../data";
import Footer from "../components/Footer";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const OurWork = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <Navbar />
        <section className="h-[75vh] sm:h-[90vh] relative flex flex-col items-center justify-center">
          <div
            className="h-[75vh] sm:h-[90vh] absolute top-0 w-full left-0 z-20"
            style={{
              backgroundColor: "#181818",
              opacity: "0.8",
            }}
          ></div>

          <div
            className="h-[75vh] sm:h-[90vh] absolute top-0 w-full left-0 bg-cover bg-center z-10"
            style={{
              backgroundImage: "url('images/ourwork.webp')",
              // zIndex: "-100",
            }}
          ></div>

          <div className="h-[75vh] sm:h-[90vh] absolute top-0 left-0 z-30 w-full flex flex-col items-center justify-center text-center px-6 lg:px-0">
            <h1 className="mb-2 text-[2rem] sm:mb-4 sm:text-[3rem]">
              OUR WORKS
            </h1>
            <p className="text-gray-300 text-md lg:w-[60%] lg:text-xl">
              See the difference in every detail! Explore our standout projects,
              where creativity meets precision. From concept to completion,
              witness how we turn ideas into impactful digital experiences.
            </p>
          </div>
        </section>
      </div>

      <section className="flex flex-col py-4 items-center h-full px-6 mb-2 lg:px-12 lg:py-0 lg:h-[60vh] lg:justify-between lg:flex-row">
        <div className="text-lg font-bold mb-4 lg:flex-1 lg:text-[2.5rem] lg:mb-0">
          PORTFOLIOS
        </div>

        <div className="text-md text-center lg:flex-1 lg:text-lg lg:text-left">
          A tech-forward company specializing in web development, mobile development, design, and
          strategic digital marketing solutions. Explore our diverse projects to
          witness innovation, creativity, and results-driven excellence.
        </div>
      </section>

      <section className="min-h-full px-4 lg:mb-10 lg:py-0">
        <div className="flex items-center justify-center flex-wrap lg:justify-start">
          {OurWorkData.map((elem) => {
            return (
                <div className="mb-4 flex items-center justify-center flex-col sm:w-[50%] sm:p-2 lg:w-[28%] lg:mb-0 lg:m-8 lg:block animation" key={elem.id}>
              <Link to={elem.link} target="_blank">
                <img rel="preload" src={elem.img} alt="Project Photo" />
                  <h1 className="text-lg mt-4 text-gray-300 text-center">{elem.title}</h1>
                </Link>
                </div>
            );
          })}
        </div>
      </section>

      <section className="h-full w-full flex justify-between items-center px-4 py-4 lg:px-12 animation" style={{backgroundColor: "#181818"}} >

          <div className="flex items-center justify-center flex-col md:flex-1 md:block">
            <h1 className="font-bold text-center text-lg mb-2 lg:text-[2rem] lg:mb-4 md:text-left">WHAT WE DO</h1>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-8 lg:text-lg">
            NetsFlow revolutionizes the digital experience by offering top-tier web and Flutter development solutions. Our expertise spans crafting responsive websites, building powerful mobile applications, and delivering dynamic digital content. We provide custom, innovative solutions designed to help your brand stand out and succeed in the ever-evolving digital landscape, ensuring your online presence is both engaging and future-ready.
            </p>
            <div className="">
            <Link to='/contact'>
              <button className="flex items-center bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition duration-300 ">
                JOIN US
                <FaArrowRight className="ml-2" />
              </button>
              </Link>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-end approachHidden">
            <img draggable="false" rel="preload" className="h-[70vh]" src="images/ourwork_whatwedo.webp" alt="" />
          </div>

        </section>

      <Footer/>
    </>
  );
};

export default OurWork;
